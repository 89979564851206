import { useContext, createContext, useState, useEffect, React } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const AuthContext = createContext();

const isTokenValid = (token) => {
  if (!token) {
    return false;
  }

  try {
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    if (!exp) {
      return false;
    }
      return Date.now() < exp * 1000;
  } catch (error) {
    return false; 
  }
}

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const loginAction = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.accessToken) {
        setUser(res.userId);
        setToken(res.accessToken);
        localStorage.setItem("site", res.accessToken);
        navigate("/dash");
        toast(`Välkommen!`, { icon: "👋" });
        return;
      } else {
      throw new Error(res.message || 'Inloggningsfel');
      }
    } catch (err) {
      return (err.message);
    } finally {
      setLoading(false);
      }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    navigate("/login");
  };

  const isAuthenticated = () => {
    return isTokenValid(token);
  }

  useEffect(() => {
    if (isAuthenticated()) {
      const token = localStorage.getItem('site');
      setUser({token});
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut, isAuthenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
}
