const faqs = [
    {
      question: 'Vad är behörighet?',
      answer: 
        'Behörigheter används för att styra åtkomstnivån för olika funktioner i Flowtrade. Det är endast administratörer och ägare som kan ändra behörighet för ett visst konto.',
        detailedAnswer: 'För att kunna ändra viktiga grundinställningar (exempelvis hantera fraktavtal och integrationer) behöver du vara inloggad som antingen administratör eller ägare. '
    },
    {
      question: 'Hur kopplar jag e-handel eller ordersystem till Flowtrade?',
      answer: 'Navigera till inställningar, och välj sedan integrationer. Klicka på "Ny integration" och fyll i uppgifterna.',
      detailedAnswer: 'Det är viktigt att uppgifterna som anges är korrekta, annars kommer inte integrationen att läggas till.'
    },
    {
      question: 'Vad är "locations"?',
      answer: 'Locations är en funktion i Shopify som gör det möjligt att administrera flera olika lager, med olika adresser och inventarier.',
      detailedAnswer: 'I Flowtrade kan du hantera en "location" per integration. Du väljer vilken location som ska användas under Inställningar > Integration.'
    }
    // More questions...
  ]
  
  export default function Faq() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="lg:col-span-5">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">FAQ</h2>
              <p className="mt-4 text-base leading-7 text-gray-600">
                Hittar du inte svaret på din fråga? Hör av dig till supporten för att få hjälp.
              </p>
            </div>
            <div className="mt-10 lg:col-span-7 lg:mt-0">
              <dl className="space-y-10">
                {faqs.map((faq) => (
                  <div key={faq.question}>
                    <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                    <dd className="mt-2 text-base leading-7 text-gray-600">{faq.detailedAnswer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    )
  }
  