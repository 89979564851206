import React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';

const ErrorMessage = ({ message }) => {
    const messageMap = {
        "Authentication Failed": "Uppgifterna stämmer inte.",
        "Failed to fetch": "Ett internt serverfel uppstod.",
        "Validation failed": "Uppgifterna stämmer inte.",
        "Too many attempts. Please try again in 15 minutes.": "För många inloggningsförsök. Prova igen om 15 minuter."
        // Lägg till fler felkoder och meddelanden här
      };
    
      const displayMessage = messageMap[message] || message;
    
    return (
        <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Hoppsan, något blev fel!</h3>
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                <li>{ displayMessage }</li>
                <li>Prova att logga in igen, eller kontakta supporten om felet kvarstår.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ErrorMessage;