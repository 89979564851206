import React from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Tooltip} from "@nextui-org/react";
import { PaperClipIcon } from '@heroicons/react/20/solid'
import courierConfig from "../../configuration/courierConfig";
import { StatusChip } from "../global/pickups";

function formatDateTime(dateString) {
  if (!dateString) return "Laddar..."; // Hanterar null eller undefined

  try {
      const date = new Date(dateString);
      const options = {
          year: 'numeric', month: '2-digit', day: '2-digit',
          hour: '2-digit', minute: '2-digit', hour12: false
      };
      return new Intl.DateTimeFormat('sv-SE', options).format(date).replace(/\//g, '-');
  } catch (error) {
      console.error("Error formatting date:", error);
      return "Datumfel"; // Returnerar en fallback-sträng vid fel
  }
}

export default function ManagePickup({ booking }) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const config = courierConfig[booking.courierId]

  return (
    <>
      <button onClick={onOpen} className="text-indigo-600 hover:text-indigo-900">Hantera</button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Upphämtning för {config.name}</ModalHeader>
              <ModalBody>
              <div>
                  <div className="px-4 sm:px-0">
                      <h3 className="text-base font-semibold leading-7 text-gray-900">Upphämtningsinformation</h3>
                      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Detaljer och dokument relaterade till upphämtningen.</p>
                  </div>
                  <div className="mt-6 border-t border-gray-100">
                      <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Transportör</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                          <img
                                src={config.imageUrl} // Exempel URL till DHL logotyp
                                alt="Carrier logo"
                                className="w-6 h-6 rounded-full mr-2"
                            />
                          {config.name}
                          </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Bokningsnummer</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{booking.bookingNumber || "Ej tillgängligt"}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Skapad</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatDateTime(booking.createdAt)}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Hämtas</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{booking.pickupDateTime ? formatDateTime(booking.pickupDateTime).split(' ')[0] : "Ingen garanterad tid"}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Totalvikt</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{booking.totalWeight ? `${booking.totalWeight} kg` : "Ej definierad"}</dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <StatusChip status={booking.status} pickupDateTime={booking.pickupDateTime} />
                          </dd>
                      </div>
                      {booking.driverInfo && (
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Instruktion till chauffören</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">"{booking.driverInfo}"</dd>
                        </div>
                      )}
                      </dl>
                  </div>
                  </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={onClose}>
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
