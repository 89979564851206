import React, { createContext, useContext, useState } from 'react';

const UpdateContext = createContext(); 

export const UpdateProvider = ({ children }) => {
    const [updateCount, setUpdateCount] = useState(0);

    return (
        <UpdateContext.Provider value={{ updateCount, setUpdateCount }}>
            {children}
        </UpdateContext.Provider>
    );
};

export const useUpdate = () => useContext(UpdateContext);