import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import {Chip} from '@nextui-org/react'
import {Spinner} from "@nextui-org/react";
import useUserData from '../../contexts/dashboardHook'
import useUserProfileUpdate from '../../contexts/profileHook'
import { React, useState } from 'react'
import { Link } from 'react-router-dom';
import GeneralError from './generalError'
import toast, { Toaster } from 'react-hot-toast';

export default function UserProfile() {

    const userData = useUserData();
    const { updateProfile, loading, error, success } = useUserProfileUpdate();
    
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const formData = {
        firstName: event.target.elements['firstName'].value,
        lastName: event.target.elements['lastName'].value,
        about: event.target.about.value,
      };
  
      try {
          const {about, ...requiredFields} = formData;
  
          if (!Object.values(requiredFields).every(value => value.trim() !== '')) {
              throw new Error("Kontrollera inmatning.");
          }
  
          const password = event.target.password.value;
          const passwordRepeat = event.target.elements['password-repeat'].value;
  
          if (password.trim() !== '' || passwordRepeat.trim() !== '') {
            if (password !== passwordRepeat) {
              throw new Error("Lösenorden matchar inte");
            }
            
            formData.password = password;
          }
  
          console.log(JSON.stringify(formData));
          await updateProfile(formData);
          // Directly await and handle the promise from updateProfile

      } catch (error) {
          // Handle synchronous errors or rejections from updateProfile
          toast.error(error.message || 'Okänt fel');
      }
  };
  

    return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Min profil</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Redigera ditt användarkonto
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                Förnamn
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset bg-white ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    defaultValue={userData?.firstName}
                    className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                Efternamn
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset bg-white ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    defaultValue={userData?.lastName}
                    className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                E-postadress
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  defaultValue={userData?.email}
                  disabled
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <p className="mt-2 text-xs text-gray-500" id="email-description">
                Det går inte att ändra e-postadressen.
              </p>
            </div>

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                Om
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={''}
                />
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-600">Berätta lite kort om dig själv :)</p>
            </div>

            <div className="col-span-full">
              <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                Profilbild
              </label>
              <div className="mt-2 flex items-center gap-x-3">
                <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                <button
                  type="button"
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Ändra
                </button>
              </div>
            </div>

            <div className="col-span-full">
                <label htmlFor='permissions' className="block text-sm font-medium leading-6 text-gray-900">
                    Behörighet
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                <Chip
                variant="shadow"
                classNames={{
                base: "bg-gradient-to-br from-indigo-700 to-indigo-400 border-small border-white/50 shadow-pink-500/30",
                content: "drop-shadow shadow-black text-white",
                  }}
                >
                  Ägare
                </Chip>
                </div>
                <p className="mt-2 text-xs text-gray-500" id="email-description">
                Hanteras av administratör. Läs mer om behörighet <Link to="/dash/faq" className="text-blue-500 hover:text-blue-700">här</Link>.
                </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Lösenord</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">Ändra ditt lösenord</p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Nytt lösenord
              </label>
              <div className="mt-2">
                <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <p className="mt-2 text-xs text-gray-500" id="email-description">
                Lämna tomt för att behålla nuvarande. Lösenordet behöver bestå av minst 6 tecken. 
                </p>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="password-repeat" className="block text-sm font-medium leading-6 text-gray-900">
                Upprepa lösenord
              </label>
              <div className="mt-2">
                <input
                  type="password"
                  name="password-repeat"
                  id="password-repeat"
                  autoComplete="password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        {loading && <Spinner />}
        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
          Avbryt
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Spara
        </button>
      </div>
    </form>
  )
}