import React, { useState, useEffect } from "react";
import { Modal, Select, SelectItem, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Checkbox, Avatar, Spinner, DatePicker, Tooltip, Progress } from "@nextui-org/react";
import courierConfig from "../../configuration/courierConfig";
import { AdjustmentsHorizontalIcon, SparklesIcon, CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { CalendarIcon } from '@heroicons/react/24/outline'
import {startOfWeek, startOfMonth, getLocalTimeZone, today} from "@internationalized/date";
import {useLocale, useDateFormatter} from "@react-aria/i18n";
import { useFetchBookings } from "../../contexts/shipmentHook";


import useFetchShipments from "../../contexts/shipmentHook";
 

export default function NewPickup({ courierData, isLoading }) {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [selectedCourier, setSelectedCourier] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [settingsToggled, setSettingsToggled] = useState(false);
    const [isAuto, setIsAuto] = useState(true);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [fullSelectedAddress, setFullSelectedAddress] = useState('');
    const [instruction, setInstruction] = useState('');
    const [courierIdentifier, setCourierIdentifier] = useState('');


    const { fetchShipments, loading, error, shipments } = useFetchShipments();
    const { bookingData, addBooking, success, rejection, error: bookingError  } = useFetchBookings();

    useEffect(() => {
        if (selectedCourier) {
            fetchShipments({ 
              courierIdentifier: selectedCourier, 
              pickupDate: selectedDate.toISOString(), // Skickar datumet i 'YYYY-MM-DD' format
            });
        }
    }, [selectedCourier, selectedDate]);

    useEffect(() => {
        onClose();
    }, [success]);


    let defaultDate = today(getLocalTimeZone());

    const [value, setValue] = React.useState(defaultDate);
  
    let {locale} = useLocale();
    let formatter = useDateFormatter({dateStyle: "full"});
  
    let now = today(getLocalTimeZone());
    let nextWeek = startOfWeek(now.add({weeks: 1}), locale);
    let nextMonth = startOfMonth(now.add({months: 1}));

    const handleCourierChange = (e) => {
      const selectedCourierIdentifier = e.target.value;  // Det unika _id som kommer från value i SelectItem
    
      // Hitta den valda transportören baserat på _id
      const selectedCourierData = courierData.find(courier => courier._id === selectedCourierIdentifier);
    
      if (selectedCourierData) {
        setSelectedCourier(selectedCourierData.courierId);  // Spara courierId (t.ex. 1 för DHL)
        setCourierIdentifier(selectedCourierData._id);  // Spara det unika _id för den valda transportören
        setIsConfirmed(false);
      }
    };

    const handleToggleSettings = () => {
        setSettingsToggled(prevState => !prevState);
        setIsAuto(false);
        setIsConfirmed(false);
    };

    const handleConfirmationClick = () => {
      setIsConfirmed(true);
    }

    const formData = {
      courierId: selectedCourier,
      pickupDate: selectedDate.toISOString(),
      pickupAddress: selectedAddress,
      pickupInstruction: instruction,
      _id: courierIdentifier,
      ids: shipments?.ids || [],
    };

    const handleSubmit = () => {
      setCurrentStep(1)
      setIsConfirmed(false)
      addBooking(formData);
      console.log(formData);
    };

    const totalShipments = shipments?.totalShipments || 0;
    const suggestedPickupDate = shipments?.suggestedPickupDate || 'n/a'
    const shipmentDate = shipments?.date || "Datum ej tillgängligt";
    const pickupAddresses = shipments?.pickupAddresses || [];

    useEffect(() => {
      // Endast sätt defaultAddress om ingen adress är vald
      if (!selectedAddress) {
        const defaultAddress = pickupAddresses.find(address => address.default === true);
        if (defaultAddress) {
          setSelectedAddress(defaultAddress._id);  // Sätt standardadress som förvald
          setFullSelectedAddress(defaultAddress);  // Spara hela adressobjektet
        }
      }
    }, [pickupAddresses]); // Körs varje gång pickupAddresses uppdateras, men inte om en adress redan är vald
    
    useEffect(() => {
      // Uppdatera fullSelectedAddress när selectedAddress ändras, men inte skriv över med standardadress
      const newSelectedAddress = pickupAddresses.find(address => address._id === selectedAddress);
      if (newSelectedAddress) {
        setFullSelectedAddress(newSelectedAddress);  // Uppdaterar full adress när selectedAddress ändras
      }
    }, [selectedAddress, pickupAddresses]); // Körs när selectedAddress eller pickupAddresses ändras

    useEffect(() => {
      if (rejection) {
        setCurrentStep(5)
      }
    }, [rejection]);

    useEffect(() => {
      if (bookingError) {
        setCurrentStep(6)
      }
    }, [bookingError]);
  
    const tryAgain = () => {
      setCurrentStep(0);
    };
  

    return (
      <>
        <button
          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={onOpen}
        >
          Boka upphämtning
        </button>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Boka upphämtning</ModalHeader>
                <ModalBody>
                  {currentStep === 0 && (
                    <>
                      <div className="flex flex-col grid-cols-1 justify-center">
                      <Select 
                        label="Transportör"
                        placeholder="Välj transportör"
                        className="max-w"
                        isDisabled={isLoading} 
                        onChange={handleCourierChange}
                      >
                        {courierData.map((courier) => (
                          <SelectItem 
                            key={courier._id} 
                            value={courier._id}
                            startContent={<Avatar alt={courier.courierName} className="w-6 h-6" src={courier.imageUrl} />}
                          >
                            {courier.courierName}
                          </SelectItem>
                        ))}
                      </Select>
                      {selectedCourier && settingsToggled && ( //lägg inställningar i detta block
                          <>
                          <div className="flex flex-row gap-x-1">
                              <div className="mt-1 flex basis-1/2 w-full flex-wrap md:flex-nowrap gap-4">
                                  <DatePicker 
                                      label="Önskat upphämtningsdatum"
                                      className="max-w-full"
                                      value={value}
                                      minValue={today(getLocalTimeZone())}
                                      onChange={(newValue) => {
                                        setValue(newValue);
                                        setSelectedDate(new Date(newValue));  // Uppdatera selectedDate när ett nytt datum väljs
                                        setIsConfirmed(false);
                                      }}
                                      description={"Observera att upphämtning på vald dag aldrig kan garanteras."}
                                  />
                              </div>
                              <div className="mt-1 flex basis-1/2 w-full flex-wrap md:flex-nowrap gap-4">
                                <Select
                                  label="Upphämtningsadress"
                                  placeholder="Välj en adress"
                                  className="max-w-full"
                                  defaultSelectedKeys={[selectedAddress]}  // Här sparar du det valda adress-id:t
                                  onChange={(e) => setSelectedAddress(e.target.value)}  // Uppdaterar när en adress väljs
                                >
                                  {pickupAddresses.map((address) => (
                                    <SelectItem key={address._id} value={address._id}>
                                      {`${address.street}`}
                                    </SelectItem>
                                  ))}
                                </Select>
                              </div>
                          </div>
                          <div className="mt-2 grid gid-cols-1">
                              <div className="col-span-full">
                                  <label htmlFor="instruction" className="block text-sm font-medium leading-6 text-gray-900">
                                      Instruktion till chauffören
                                  </label>
                                  <div className="mt-1">
                                      <textarea
                                      id="instruction"
                                      name="instruction"
                                      placeholder="Portkoden är 1657..."
                                      rows={3}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      defaultValue={''}
                                      onChange={(e) => setInstruction(e.target.value)}
                                      />
                                  </div>
                              </div>
                          </div>
                          </>
                      )}
                    </div>
                    {selectedCourier && (
                      <div className="relative">
                          <div aria-hidden="true" className="absolute inset-0 flex items-center">
                          <div className="w-full border-t border-gray-300" />
                          </div>
                          <div className="relative flex justify-center">
                          <button
                              type="button"
                              className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={handleToggleSettings}
                          >
                              {settingsToggled ? <CheckIcon aria-hidden="true" className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" /> : <AdjustmentsHorizontalIcon aria-hidden="true" className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" /> }
                              {settingsToggled ? 'OK' : 'Inställningar'}
                          </button>
                          </div>
                      </div>
                    )}
                    {selectedCourier && totalShipments !== 0 && (
                      <>
                      {isAuto && (
                          <div className="flex justify-center">
                              <p className="text-sm font-medium text-gray-900">Auto</p>
                              <SparklesIcon aria-hidden="true" className="ml-1 h-5 w-5 text-yellow-500" />
                          </div>
                      )}
                      <div className="mt-1 grid grid-cols-1 gap-1">
                          <div className="flex justify-between items-center overflow-hidden rounded-lg px-4 py-2 shadow sm:p-6">
                            <p className="text-sm font-medium text-gray-500">Upphämtningsadress</p>
                            <div className="h-10 grid grid-cols-1 items-center gap-0">
                              {loading ? <Spinner /> : 
                                <p className="text-lg font-semibold tracking-tight text-gray-900 overflow-hidden whitespace-nowrap text-ellipsis">
                                  {fullSelectedAddress.street}
                                </p>
                              }
                            </div>
                          </div>
                          <div className="flex justify-between items-center overflow-hidden rounded-lg px-4 py-2 shadow sm:p-6">
                              <Tooltip content="Frakter som kommer bokas för upphämtning">
                                  <p className="text-sm font-medium text-gray-500">Antal sändningar</p>
                              </Tooltip>
                              <div className="h-10 flex items-center justify-center">
                                  {loading ? <Spinner /> : <p className="text-3xl font-semibold tracking-tight text-gray-900">{totalShipments} st</p>}
                              </div>
                          </div>
                          <div className="flex justify-between items-center overflow-hidden rounded-lg px-4 py-2 shadow sm:p-6">
                              <Tooltip content="Omräknat till nästa möjliga arbetsdag">
                                  <p className="text-sm font-medium text-gray-500">Prel. upphämtningsdatum</p>
                              </Tooltip>
                              <div className="h-10 flex items-center justify-center">
                                  {loading ? <Spinner /> : <p className="text-3xl font-semibold tracking-tight text-gray-900">{suggestedPickupDate}</p>}
                              </div>
                          </div>
                      </div>
                      <div className="relative flex justify-center items-center gap-5">
                          {isAuto ? <p className="text-sm font-medium text-gray-500">Använda automatiska uppgifter?</p> : <p className="text-sm font-medium text-gray-500">Stämmer detta?</p> }
                          <Button color="primary" size="sm" onClick={handleConfirmationClick} isDisabled={isConfirmed}>
                              OK
                          </Button>
                          {isConfirmed && (
                            <>
                              <p className="text-sm font-medium text-gray-900"></p>
                              <CheckIcon aria-hidden="true" className="ml-1 h-5 w-5 text-green-500" />
                            </>
                          )}
                      </div>
                      </>
                    )}

                    {selectedCourier && totalShipments === 0 && (
                      <div className="mt-1 grid grid-cols-1 gap-1">
                        <div className="flex justify-center items-center overflow-hidden rounded-lg px-4 py-2 shadow sm:p-6">
                                <p className="text-sm font-medium text-gray-500">Hittade inga sändningar att boka.</p>
                        </div>
                    </div>
                    )}

                      </>
                  )}
                  {currentStep === 1 && (
                    <>
                    <label className="block text-sm font-medium text-gray-700">Begär upphämtning...</label>
                    <Progress
                      size="sm"
                      isIndeterminate
                      aria-label="Loading..."
                      className="max-w-md"
                    />
                    </>
                  )} 
                  {currentStep === 5 && (
                    <div className="rounded-md bg-yellow-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-yellow-800">Ett fel uppstod</h3>
                          <div className="mt-2 text-sm text-yellow-700">
                            {/* Första stycket: Förklaring till användaren */}
                            <p>
                              Bokningen kunde inte genomföras eftersom fraktbolaget nekade den.
                            </p>

                            {/* Andra stycket: Felmeddelande från fraktbolaget med speciell styling */}
                            <p className="mt-4">
                              Detta är felmeddelandet från transportören:
                            </p>
                            <p className="mt-2 text-yellow-800 bg-yellow-100 p-2 rounded-md shadow-lg">
                              {rejection}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentStep === 6 && (
                    <div className="rounded-md bg-yellow-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-yellow-800">Ett fel uppstod</h3>
                          <div className="mt-2 text-sm text-yellow-700">
                            {/* Första stycket: Förklaring till användaren */}
                            <p>
                              Bokningen kunde inte genomföras på grund av ett serverfel.
                            </p>

                            {/* Andra stycket: Felmeddelande från fraktbolaget med speciell styling */}
                            <p className="mt-4">
                              Detta är felmeddelandet från servern:
                            </p>
                            <p className="mt-2 text-yellow-800 bg-yellow-100 p-2 rounded-md shadow-lg">
                              {bookingError}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose}>
                    Avbryt
                  </Button>
                  {currentStep === 0 && (
                    <Button color="primary" onPress={handleSubmit} isDisabled={!isConfirmed}>
                      Genomför bokning
                    </Button>
                  )}
                  {currentStep === 5 && (
                  <Button color="primary" onPress={tryAgain}>
                    Testa igen
                  </Button>
                  )}
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    );
}