import { useState, useEffect } from 'react';
import { useUpdate } from './updateContext';
import toast, { Toaster } from 'react-hot-toast';

function useFetchPdfData() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  const fetchPdfData = async (orderId) => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('site');

      const response = await fetch(`${process.env.REACT_APP_GET_CUSTOMS_ORDER_URL}?orderId=${orderId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token}`,
        }
        });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPdfData(data || []);
    } catch (error) {
      setError(error.message);
      toast.error('Fel vid hämtning av PDF-data');
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, pdfData, fetchPdfData };
}

export default useFetchPdfData;