import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  ForwardIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  PaperAirplaneIcon,
  CubeIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  MoonIcon,
  BuildingOffice2Icon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { ThemeProvider, useTheme } from '../../contexts/ThemeContext.js';
import logo_light from '../../assets/flowtrade-high-resolution-logo-transparent.png'
import logo_dark from '../../assets/flowtrade-high-resolution-logo-transparent-white.png'
import { useAuth } from '../../contexts/AuthContext.js';
import useUserData from '../../contexts/dashboardHook.js';
import DashSkeleton from '../global/dashSkeleton';
import { Link, Outlet } from 'react-router-dom';
import LogoutDialog from '../global/logoutDialog.jsx';
import { Logout } from '@mui/icons-material';
import { Toaster } from 'react-hot-toast';
import { Tooltip } from "@nextui-org/react";
import packageJson from '../../../package.json';



const navigation = [
    { name: 'Dashboard', href: '', icon: HomeIcon, current: false, action: 'navigate' },
    { name: 'Beställningar', href: 'orders', icon: CubeIcon, current: false, action: 'navigate' },
    { name: 'Upphämtningar', href: 'pickups', icon: ForwardIcon, current: false, action: 'navigate' },
    { name: 'Användare', href: 'orders', icon: UsersIcon, current: false, disabled: true },
    { name: 'Rapporter', href: '#', icon: ChartPieIcon, current: false, disabled: true },
  ]
  const teams = [
    { id: 1, name: 'Skapa tullfaktura', href: 'customs', initial: 'T', current: false, disabled: false },
    { id: 2, name: 'Spåra en leverans', href: '#', initial: 'S', current: false, disabled: true },
    { id: 3, name: 'Fakturor', href: '#', initial: 'F', current: false, disabled: true },
  ]
  const userNavigation = [
    { name: 'Organisation', href: 'profile', action: 'navigate' },
    { name: 'Min profil', href:'user-profile', action: 'navigate' },
    { name: 'Logga ut', href: '#', action: 'logout'},
  ]
  
  // some function stuff

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  
const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showLogoutDialog, setLogoutDialog] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(false);

  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Dashboard');

  const tabs = navigation.map(tab => ({
    ...tab,
    current: tab.name === activeTab,
  }));

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName); // Uppdaterar tillståndet och lagrar det nya aktiva tab-namnet
  };

  const auth = useAuth();

  const handleLogoutClick = () => {
    setLogoutDialog(true);
  }
  const handleConfirmLogout = () => {
    auth.logOut()
    setLogoutDialog(false);
  }
  const handleCancelLogout = () => {
    setLogoutDialog(false);
  }

  const { toggleTheme } = useTheme();
  const { theme } = useTheme(); 
  const logo = theme === 'dark' ? logo_dark : logo_light;
  const userData = useUserData();

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <html className="h-full w-full bg-zinc-50 overflow-hidden">
      <div className="min-h-screen dark:bg-gray-700">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                          {tabs.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.disabled ? '#' : item.href}
                                className={classNames(
                                  item.disabled
                                    ? 'text-gray-400 cursor-not-allowed' // Klass för inaktiverade flikar
                                    : item.current
                                    ? 'bg-gray-50 dark:bg-gray-700 text-indigo-600 dark:text-white'
                                    : 'text-gray-600 hover:text-indigo-600 dark:hover:text-indigo-500 hover:bg-gray-50 dark:hover:bg-gray-700',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                                onClick={(e) => {
                                  if (item.disabled) {
                                    e.preventDefault(); // Förhindra navigering för inaktiverade flikar
                                  } else {
                                    handleTabClick(item.name);
                                    setSidebarOpen(false);
                                  }
                                }}
                              >
                                <item.icon
                                  className={classNames(
                                    item.disabled
                                      ? 'text-gray-300' // Gör ikonen grå för inaktiverade flikar
                                      : item.current
                                      ? 'text-indigo-600'
                                      : 'text-gray-400 group-hover:text-indigo-600',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Snabbåtgärder</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <Link
                                  to={team.disabled ? '#' : team.href}
                                  className={classNames(
                                    team.disabled
                                      ? 'text-gray-400 cursor-not-allowed' // Klass för inaktiverade länkar
                                      : team.current
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                  aria-disabled={team.disabled ? 'true' : 'false'}
                                  onClick={(e) => {
                                    if (team.disabled) {
                                      e.preventDefault(); // Förhindra navigering om länken är inaktiverad
                                    }
                                  }}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? 'text-indigo-600 border-indigo-600'
                                        : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <Link
                            to={"settings/general"}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            Inställningar
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white dark:bg-gray-800 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-7 w-auto"
                src={logo}
                alt="Flowtrade"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {tabs.map((item) => (
                      <li key={item.name}>
                        {item.disabled ? (
                          <Tooltip content="Kommer snart..." placement='right' className='-ml-10'>
                            <div
                              className={classNames(
                                'text-gray-400 cursor-not-allowed group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  'text-gray-300', // Gör ikonen grå för inaktiverade flikar
                                  'h-6 w-6 shrink-0'
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </div>
                          </Tooltip>
                        ) : (
                          <Link
                            to={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-50 dark:bg-gray-700 text-indigo-600 dark:text-white'
                                : 'text-gray-600 hover:text-indigo-600 dark:hover:text-indigo-500 hover:bg-gray-50 dark:hover:bg-gray-700',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                            onClick={() => handleTabClick(item.name)}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? 'text-indigo-600'
                                  : 'text-gray-400 group-hover:text-indigo-600',
                                'h-6 w-6 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Snabbåtgärder</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        {team.disabled ? (
                          <Tooltip content="Kommer snart..." placement='right' className='-ml-10'>
                            <div className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 cursor-not-allowed">
                              <span
                                className={classNames(
                                  'text-gray-300 border-gray-200',
                                  'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                )}
                              >
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </div>
                          </Tooltip>
                        ) : (
                          <Link
                            to={team.href}
                            className={classNames(
                              team.current
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                            onClick={(e) => {
                              if (team.disabled) {
                                e.preventDefault(); // Förhindra navigering om länken är inaktiverad
                              }
                            }}
                          >
                            <span
                              className={classNames(
                                team.current
                                  ? 'text-indigo-600 border-indigo-600'
                                  : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                              )}
                            >
                              {team.initial}
                            </span>
                            <span className="truncate">{team.name}</span>
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">Välkommen till Flowtrade.</h3>
                      <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>
                          Appen är för närvarande under full utveckling och funktioner adderas kontinuerligt.
                        </p>
                      </div>
                      <div className="mt-3 text-sm leading-6">
                        <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                          Läs mer
                          <span aria-hidden="true"> &rarr;</span>
                        </a>
                      </div>
                    </div>
                </div>
                </li>
                <li className="mt-auto">
                  <Link
                    to={'settings/general'}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                    Inställningar
                  </Link>
                </li>
                <p className="text-xs font-semibold leading-6 text-gray-400">
                  © Flowtrade {packageJson.version}
                </p>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-200 dark:gray-800 dark:gra lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Snabbsök
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Snabbsök..."
                  type="search"
                  name="search"
                />
              </form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
              <button type="button" className="relative -m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                {unreadNotifications && (
                  <span className="absolute top-0 right-0 inline-flex items-center justify-center h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-sky-500"></span>
                  </span>
                )}
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

                <button onClick={toggleTheme} type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Toggle dark mode</span>
                  <MoonIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                          {userData ? userData.name : <DashSkeleton />}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                          {({ active }) => (
                            item.action === 'logout' ? (
                              <button
                                onClick={handleConfirmLogout}
                                className={classNames(
                                  active ? 'bg-gray-50' : '',
                                  'block w-full text-left px-3 py-1 text-sm leading-6 text-red-500'
                                )}
                              >
                                {item.name}
                              </button>
                            ) : (
                              <Link to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                {item.name}
                              </Link>
                            )
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="flex-grow py-10">
            <div className="px-4 sm:px-6 lg:px-8">{<Outlet />}</div>
          </main>
        </div>
      </div>
      </html>
    </>
  )
}

export default Sidebar;
