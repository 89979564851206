import { useState, useEffect }  from 'react';
import toast, { Toaster } from 'react-hot-toast';

function usePackage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [packageData, setPackageData] = useState(null);
    const [active, setActive] = useState(null);
    const [success, setSuccess] = useState(null);

    const fetchPackage = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_PACKAGE_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while fetching package'
            }
            setPackageData(data.package.sizes);
            setActive(data.package.active);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av paket');
        } finally {
            setLoading(false);
        }
    }

    const addPackage = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_PACKAGE_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while adding package'
            }
            setSuccess(true);
            setPackageData(data.package.sizes);
            toast.success('Paketmallen lades till.');
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid tillägg av paket');
        } finally {
            setLoading(false);
        }
    }

    const deletePackage = async (id) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_PACKAGE_URL}/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }, 
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while deleting package'
            }
            setSuccess(true);
            setPackageData(data.package.sizes);
            toast.success('Paketmallen togs bort.');
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid radering av paket');
        } finally {
            setLoading(false);
        }
    }

    const setActivePackage = async (formData) => {
        setError(null);

        try {
        const token = localStorage.getItem('site');
        const response = await fetch(`${process.env.REACT_APP_PACKAGE_URL}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message) || 'An error occurred while deleting package'
        }
        setActive(data.package.active);
        toast.success(data.package.active ? 'Paketmallar aktiverades!' : 'Paketmallar inaktiverades!');
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid aktivering av paketmallar');
        } 

    }

    return { setActivePackage, deletePackage, addPackage, fetchPackage, loading, error, packageData, success, active };
}

export default usePackage;