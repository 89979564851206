import React, { useEffect, useState } from 'react';
import AddIntegrationModal from "./addIntegrationModal";
import { useIntegrationData } from "../../contexts/integrationHook";
import integrationConfig from '../../configuration/integrationConfig';
import EmptyStateInt from './emptyStateInt';
import TableSkeleton from '../global/tableSkeleton';
import IntegrationSettings from './integrationSettingsModal'


export default function Integrations() {
  const { integrationData, loading } = useIntegrationData(); // Assuming this returns an array or null/undefined
  


  return (
    <div className="px-4 sm:px-6 lg:px-8 pt-6">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Integrationer</h1>
          <p className="mt-2 text-sm text-gray-700">
            Hantera organisationens integrationer till ordersystem.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <AddIntegrationModal />
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-white rounded-lg shadow">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Plattform
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Info
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Inställning
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {loading ? (
                  // Assuming the Skeleton component can span the entire row
                  <tr>
                    <td colSpan="5"><TableSkeleton /></td>
                  </tr>
                ) : integrationData && integrationData.length > 0 ? (
                  integrationData.map((integration, index) => {
                    // Assuming integrationConfig is accessible or replaced with direct data
                    const config = integrationConfig[integration.platformId] || {
                      name: 'Unknown Integration',
                      imageUrl: '', // Fallback image URL
                    };
                    
                    return (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <img className="h-10 w-10 rounded-full" src={config.imageUrl} alt={config.name} />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">{config.name}</div>
                              <div className="font-medium text-gray-500">{integration.domain || integration.integrationName}</div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="text-gray-500">ID {integration._id}</div>
                          Skapad {integration.createdAt.slice(0, 10)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                            integration.active 
                              ? "bg-green-50 text-green-700 ring-green-600/20" 
                              : "bg-red-50 text-red-700 ring-red-600/10"
                          }`}>
                            {integration.active ? "Aktiv" : "Inaktiv"}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          {integration.webhookActive ? 'Webhook' : 'Polling'}
                        </td>
                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <IntegrationSettings integration={integration}/>
                            {integration.setup !== true && (
                              <span className="absolute ml-1 inline-flex items-center justify-center h-3 w-3">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-2 w-2 bg-sky-500"></span>
                              </span>
                            )}
                            <a className="text-indigo-600 hover:text-indigo-900"></a>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center py-5"><EmptyStateInt/></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
