import { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';


function useUserProfileUpdate() {
    const [profileData, setProfileData] = useState(null);
    const [pickupAddresses, setPickupAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const updateProfile = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_UPDATE_PROFILE_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (!response.ok) {
                const errorMessage = data.message || "Ett serverfel inträffade"
                throw new Error(errorMessage);
            }
            setProfileData(data);
            setSuccess(true);
            toast.success('Uppdateringen lyckades!');
        } catch (err) {
            setError(err.message);
            toast.error('Uppdateringen misslyckades');
        } finally {
            setLoading(false);
        }
    };

    const setPassword = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(process.env.REACT_APP_RESET_PASSWORD_URL, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (!response.ok) {
                const errorMessage = data.message || "Ett serverfel inträffade"
                throw new Error(errorMessage);
            }
            setSuccess(true);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const resetPassword = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(process.env.REACT_APP_RESET_PASSWORD_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (!response.ok) {
                const errorMessage = data.message || "Ett serverfel inträffade"
                throw new Error(errorMessage);
            }
            setSuccess(true);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }

    }

    const addShippingAddress = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_ADDRESS_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (!response.ok) {
                const errorMessage = data.message || "Ett serverfel inträffade"
                throw new Error(errorMessage);
            }
            setPickupAddresses(data.pickupAddresses);
            setSuccess(true);
            toast.success('Adressen lades till!');
        } catch (err) {
            setError(err.message);
            toast.error('Adressen kunde inte läggas till. Dubbelkolla att alla fält är korrekt ifyllda.');
        } finally {
            setLoading(false);
        }
    }

    const removeShippingAddress = async (addressId) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_ADDRESS_URL}/${addressId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (!response.ok) {
                const errorMessage = data.message || "Ett serverfel inträffade"
                throw new Error(errorMessage);
            }
            setPickupAddresses(data.pickupAddresses);
            setSuccess(true);
            toast.success('Addressen togs bort!');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const setDefaultShippingAddress = async (addressId) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_ADDRESS_URL}/default/${addressId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (!response.ok) {
                const errorMessage = data.message || "Ett serverfel inträffade"
                throw new Error(errorMessage);
            }
            setPickupAddresses(data.pickupAddresses);
            toast.success('Standardadressen uppdaterades!');
            setSuccess(true);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const getPickupAddresses = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_ADDRESS_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (!response.ok) {
                const errorMessage = data.message || "Ett serverfel inträffade"
                throw new Error(errorMessage);
            }
            setPickupAddresses(data.pickupAddresses);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    return { updateProfile, setPassword, resetPassword, addShippingAddress, removeShippingAddress, setDefaultShippingAddress, getPickupAddresses, setDefaultShippingAddress, pickupAddresses, profileData, loading, error, success };
}

export default useUserProfileUpdate;