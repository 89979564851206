import { useState, useEffect } from 'react'
import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import NewPickup from '../components/newPickupModal'
import { useFetchBookings } from '../../contexts/shipmentHook'
import { SkeletonRow } from './tableSkeleton'
import courierConfig from "../../configuration/courierConfig";
import ManagePickup from '../components/managePickupModal'
import { useCourierData } from "../../contexts/courierHook";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function StatusChip({ status, pickupDateTime }) {
    let bgColor = 'bg-indigo-50';
    let textColor = 'text-indigo-700';
    let borderColor = 'ring-indigo-700/10';
    let statusMessage = 'Väntande';

    if (status === 'completed') {
        bgColor = 'bg-gray-50';
        textColor = 'text-gray-600';
        borderColor = 'ring-gray-500/10';
        statusMessage = 'Utförd'; 
    } else if (status === 'canceled') {
        bgColor = 'bg-red-50';
        textColor = 'text-red-700';
        borderColor = 'ring-red-600/20';
        statusMessage = 'Avbokad';
    } else if (status === 'confirmed') {
        bgColor = 'bg-green-50';
        textColor = 'text-green-700';
        borderColor = 'ring-green-600/20';
        statusMessage = 'Bekräftad';
    }

    return (
        <span className={`inline-flex items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${borderColor}`}>
            {statusMessage}
        </span>
    );
}


function Pagination({ currentPage, totalPages, onPageChange }) {
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
        };

        const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
        };

    return (
        <nav className="flex items-center justify-between border-gray-200 px-4 sm:px-0">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${currentPage === 1 ? 'text-gray-300' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
          >
            <ArrowLongLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
            Föregående
          </button>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {/* Här kan du lägga till logik för att visa sidnummer */}
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => onPageChange(index + 1)}
              className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${currentPage === index + 1 ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${currentPage === totalPages ? 'text-gray-300' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
          >
            Nästa
            <ArrowLongRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </nav>
    )
}

export default function Pickups () {
    const [selectedCourier, setSelectedCourier] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { fetchBookings, loading, error, bookings } = useFetchBookings();
    const { courierData, loading: isLoading } = useCourierData();

    useEffect(() => {
        fetchBookings({
            page: currentPage,
            courierId: selectedCourier,
            status: selectedStatus,
            direction: 'desc'
        });
    }, []);

    useEffect(() => {
        if (bookings) {
            setTotalPages(bookings.totalPages);  // Uppdatera totalPages från API-svaret
        }
    }, [bookings]);

    const bookingsArray = bookings?.bookings || [];

    const handleCourierChange = (event) => {
        const courierId = event.target.value;
        setSelectedCourier(courierId);
        fetchBookings({
            page: currentPage,
            courierId: courierId,
            status: selectedStatus,
            direction: 'desc'
        });
    }
    
    const handleStatusChange = (event) => {
        const status = event.target.value;
        setSelectedStatus(status);
        fetchBookings({
            page: currentPage,
            courierId: selectedCourier,
            status: status,
            direction: 'desc'
        })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchBookings({
            page: page,
            courierId: selectedCourier,
            status: selectedStatus,
            direction: 'desc',
        })
    }


    return (
        <>
        <div className="mx-auto sm:mt-4 max-w-7xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    Upphämtningar
                </h2>
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                    <p className="mt-2 text-sm text-gray-700">
                        Visa, boka och hantera upphämtningar av bokade sändningar.
                    </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <NewPickup courierData={courierData} isLoading={isLoading} />
                    </div>
                </div>
            <div className="mt-2 mx-auto max-w-5xl">
                <div className="flex flex-row space-x-2 sm:w-1/2">
                    <div className="basis-1/2">
                        <div>
                        <select
                            id="location"
                            name="location"
                            defaultValue="Alla transportörer"
                            onChange={handleCourierChange}
                            disabled={isLoading}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value="">Alla transportörer</option>
                            {courierData?.map((courier) => (
                                <option key={courier._id} value={courier.courierId}>
                                    {courier.courierName}
                                </option>
                            ))}
                        </select>
                        </div>
                    </div>
                    <div className="basis-1/4">
                        <div>
                        <select
                            id="status"
                            name="status"
                            defaultValue=""
                            onChange={handleStatusChange}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value="">Status</option>
                            <option value="pending">Väntande</option>
                            <option value="confirmed">Bekräftad</option>
                            <option value="canceled">Avbokad</option>
                            <option value="completed">Utförd</option>
                        </select>
                        </div>
                    </div>
                </div>
                <div className="px-4 sm:px-6 lg:px-8 bg-white rounded-md sm:rounded-xl shadow">
                    <div className="py-4 mt-8 flow-root">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                <th
                                    scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                >
                                    Skapad
                                </th>
                                <th
                                    scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                >
                                    Transportör
                                </th>
                                <th
                                    scope="col"
                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                >
                                    Adress
                                </th>
                                <th
                                    scope="col"
                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                >
                                    Status
                                </th>
                                <th
                                    scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                                >
                                    <span className="sr-only">Hantera</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="5"><SkeletonRow /></td>
                                    </tr>
                                ) : bookingsArray.length > 0 ? (
                                    bookingsArray.map((booking, index) => {
                                        const courier = courierConfig[booking.courierId] || {};
                                        return (
                                            <tr key={booking._id}>
                                                <td
                                                    className={classNames(
                                                        index !== bookingsArray.length - 1 ? 'border-b border-gray-200' : '',
                                                        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                                    )}
                                                >
                                                    {new Intl.DateTimeFormat('sv-SE').format(new Date(booking.createdAt))}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        index !== bookingsArray.length - 1 ? 'border-b border-gray-200' : '',
                                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                                                    )}
                                                >
                                                    <img className="h-10 w-10 rounded-full" src={courier.imageUrl} alt={courier.name} />
                                                </td>
                                                <td
                                                    className={classNames(
                                                        index !== bookingsArray.length - 1 ? 'border-b border-gray-200' : '',
                                                        'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell',
                                                    )}
                                                >
                                                    <div>
                                                        {booking.pickupAddress.street}
                                                    </div>
                                                    <div>
                                                        {booking.pickupAddress.cityName}, {booking.pickupAddress.postalCode} ({booking.pickupAddress.countryCode})
                                                    </div>
                                                </td>
                                                <td
                                                    className={classNames(
                                                        index !== bookingsArray.length - 1 ? 'border-b border-gray-200' : '',
                                                        'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell',
                                                    )}
                                                >
                                                    <StatusChip status={booking.status} pickupDateTime={booking.pickupDateTime} />
                                                </td>
                                                <td
                                                    className={classNames(
                                                        index !== bookingsArray.length - 1 ? 'border-b border-gray-200' : '',
                                                        'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                                                    )}
                                                >
                                                    <ManagePickup booking={booking} />
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center py-5">Inga upphämtningar hittades.</td>
                                    </tr>
                                )}
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                 />
            </div>
        </div>
        </>
    )
}

