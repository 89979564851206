const integrationConfig = {
    0: {
      name: 'Flowtrade',
      imageUrl: '', // Fallback image URL
    },
    1: { 
      name: 'Shopify', 
      imageUrl: 'https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-primary-logo-456baa801ee66a0a435671082365958316831c9960c480451dd0330bcdae304f.svg',
    },
    4: { 
      name: 'Nyehandel', 
      imageUrl: 'https://flowtrade.s3.eu-north-1.amazonaws.com/assets/logos/nyehandel-logo-500x500.png',
    },
    3: {
        name: 'Shopify',
        imageUrl: 'https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-primary-logo-456baa801ee66a0a435671082365958316831c9960c480451dd0330bcdae304f.svg'
    }
    // Add more as needed
  };
  
  export default integrationConfig;