import { useLayoutEffect, useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { MoonIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import useFetchOrders from '../../contexts/orderHook'
import TableSkeleton, { SkeletonRow } from './tableSkeleton'
import { useIntegrationData } from '../../contexts/integrationHook'
import {Chip} from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function formatDateTime(dateString) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: false
    };
    // Använd 'sv-SE' eller annan lämplig locale beroende på önskat språk och format
    return new Intl.DateTimeFormat('sv-SE', options).format(date).replace(/\//g, '-');
  }


function DeliverableChip({ status }) {
    let bgColor = 'bg-blue-50';
    let textColor = 'text-blue-700';
    let borderColor = 'ring-blue-700/10';
    let statusMessage = 'Redo';

    if (status === 'sent') {
        bgColor = 'bg-green-50';
        textColor = 'text-green-700';
        borderColor = 'ring-green-600/20';
        statusMessage = 'Skickad';
    } else if (status === 'partial') {
        bgColor = 'bg-blue-50';
        textColor = 'text-blue-700';
        borderColor = 'ring-blue-600';
        statusMessage = 'Dellevererad';
    } else if (status === 'cancelled') {
        bgColor = 'bg-red-50';
        textColor = 'text-red-700';
        borderColor = 'ring-red-600/20';
        statusMessage = 'Avbruten';
    } else if (status === 'refunded') {
        bgColor = 'bg-yellow-50';
        textColor = 'text-yellow-800';
        borderColor = 'ring-yellow-600/20';
        statusMessage = 'Återbetald';
    } else if (status === 'external') {
      bgColor = 'bg-yellow-50';
      textColor = 'text-yellow-800';
      borderColor = 'ring-yellow-600/20';
      statusMessage = 'Extern';
  } 

    return (
        <span className={`inline-flex ring-ye items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${borderColor}`}>
            {statusMessage}
        </span>
    );
}

function Pagination({ currentPage, totalPages, onPageChange }) {
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <nav className="flex items-center justify-between border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${currentPage === 1 ? 'text-gray-300' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
          Föregående
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {/* Här kan du lägga till logik för att visa sidnummer */}
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => onPageChange(index + 1)}
            className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${currentPage === index + 1 ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${currentPage === totalPages ? 'text-gray-300' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
        >
          Nästa
          <ArrowLongRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
}




export default function Orders() {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(localStorage.getItem('selectedIntegration') || '');
  const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
  const [country, setCountry] = useState(localStorage.getItem('country') || 'all');
  const [status, setStatus] = useState(localStorage.getItem('status') || 'all');
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const [direction, setDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { integrationData, loading: loadingIntegrations } = useIntegrationData();
  const { loading, error, orderData, metaData, fetchOrders } = useFetchOrders();

  const memoizedFetchOrders = useCallback(fetchOrders, []);
  const debouncedFetchOrders = useMemo(() => debounce((params) => memoizedFetchOrders(params), 500), [memoizedFetchOrders]);

  useEffect(() => {
    // Spara status till localStorage när det ändras
    localStorage.setItem('status', status);
  }, [status]);

  useEffect(() => {
    localStorage.setItem('country', country);
  }, [country]);

  useEffect(() => {
    localStorage.setItem('selectedIntegration', selectedIntegration);
  }, [selectedIntegration]);

  useEffect(() => {
    localStorage.setItem('searchTerm', searchTerm);
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    debouncedFetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: currentPage,
    });
  };

  const handleIntegrationChange = (event) => {
    const integration = event.target.value;
    setSelectedIntegration(integration);
    fetchOrders({
      search: searchTerm,
      integrationName: integration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: currentPage,
    });
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setCountry(country);
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: currentPage,
    });
  };

  const handleStatusChange = (event) => {
    const status = event.target.value;
    setStatus(status);
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: currentPage,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: page,
    });
  };

  useEffect(() => {
    fetchOrders({
      search: searchTerm,
      integrationName: selectedIntegration,
      country,
      status,
      date_range: dateRange,
      direction,
      page: currentPage,
    });
  }, [dateRange, direction, currentPage]);

  useEffect(() => {
    if (metaData) {
      setTotalPages(metaData.totalPages || 1);
    }
  }, [metaData]);

  useLayoutEffect(() => {
    const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < orderData?.length;
    setChecked(selectedPeople.length === orderData?.length && orderData?.length > 0);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedPeople, orderData]);

  const toggleAll = () => {
    setSelectedPeople(checked || indeterminate ? [] : orderData);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const handleFetchClick = () => {
    console.log('something');
  };

  const navigate = useNavigate();
  const handleRowClick = (orderId) => {
    navigate(`/dash/orders/${orderId}`);
  };

  return (
      <div className="flex flex-col space-x-0 space-y-12 sm:space-x-0 mt-4 mx-auto max-w-5xl items-start">
          <div className="flex flex-col sm:flex-row items-center justify-between">
              <div className="flex-1">
                  <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                      Beställningar
                  </h2>
              </div>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                  <div>
                      <div className='max-w-md mx-auto'>
                          <div className="relative flex items-center w-full h-12 overflow-hidden">
                              <div className="grid place-items-center h-full w-12 text-gray-300">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                  </svg>
                              </div>
                              <input
                                  className="block miun-w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  type="text"
                                  id="search"
                                  placeholder="Sök beställning.."
                                  value={searchTerm}
                                  onChange={handleSearchChange}
                              />
                          </div>
                      </div>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <div>
                          <select
                              id="integration"
                              name="integration"
                              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={selectedIntegration}
                              onChange={handleIntegrationChange}
                              disabled={loadingIntegrations}>
                              <option value="">Alla integrationer</option>
                              {integrationData?.map((integration) => (
                                  <option key={integration._id} value={integration._id}>
                                      {integration.integrationName}
                                  </option>
                              ))}
                          </select>
                      </div>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <div>
                          <select
                              id="country"
                              name="country"
                              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={country}
                              onChange={handleCountryChange}>
                              <option value="all">Alla länder</option>
                              <option value="SE">Sverige</option>
                              <option value="NO">Norge</option>
                              {/* Lägg till fler länder vid behov */}
                          </select>
                      </div>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <div>
                          <select
                              id="status"
                              name="status"
                              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={status}
                              onChange={handleStatusChange}>
                              <option value="all">Alla beställningar</option>
                              <option value="open">Ohanterade beställningar</option>
                              <option value="cancelled">Avbrutna beställningar</option>
                              <option value="sent">Skickade beställningar</option>
                              {/* Lägg till fler statusar vid behov */}
                          </select>
                      </div>
                  </div>
              </div>
              <div className="mt-8 py-4 flow-root rounded-xl bg-white shadow">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="relative">
                              {selectedPeople.length > 0 && (
                                  <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                      <button
                                          type="button"
                                          className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                      >
                                          <ClipboardDocumentIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                          Skapa plocklistor
                                      </button>
                                      <button
                                          type="button"
                                          className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                      >
                                          Hantera
                                          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </button>
                                  </div>
                              )}
                              <table className="min-w-full table-fixed divide-y divide-gray-300">
                                  <thead>
                                      <tr>
                                          <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                              <input
                                                  type="checkbox"
                                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                  ref={checkbox}
                                                  checked={checked}
                                                  onChange={toggleAll}
                                              />
                                          </th>
                                          <th scope="col" className="lg:min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                              Order-ID
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                              Beställare
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                              Email
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                              Belopp
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                              Status
                                          </th>
                                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                              <span className="sr-only">Edit</span>
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                      {loading ? (
                                          <SkeletonRow />
                                      ) : orderData && orderData.length > 0 ? (
                                          orderData.map((order) => {
                                              return (
                                                  <tr key={order._id} onClick={() => handleRowClick(order._id)} className={selectedPeople.includes(order) ? 'bg-gray-100' : 'cursor-pointer hover:bg-gray-100'}>
                                                      <td className="relative px-7 sm:w-12 sm:px-6">
                                                          {selectedPeople.includes(order) && (
                                                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                                          )}
                                                          <input
                                                              type="checkbox"
                                                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                              value={order.email}
                                                              checked={selectedPeople.includes(order)}
                                                              onClick={(e) => e.stopPropagation()}
                                                              onChange={(e) =>
                                                                  setSelectedPeople(
                                                                      e.target.checked
                                                                          ? [...selectedPeople, order]
                                                                          : selectedPeople.filter((p) => p !== order)
                                                                  )
                                                              }
                                                          />
                                                      </td>
                                                      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
                                                          {order.platformOrderId}
                                                      </td>
                                                      <td className="whitespace-nowrap px-3 py-4">
                                                          <div className="text-sm font-medium text-gray-900">
                                                              {order.billingAddress.company ? order.billingAddress.company : `${order.customer.firstName} ${order.customer.lastName}`}
                                                          </div>
                                                          <div className="text-sm text-gray-500">{formatDateTime(order.createdAt)}</div>
                                                      </td>
                                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.customer.email}</td>
                                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.total_price} {order.total_price && 'SEK'}</td>
                                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="grid grid-cols-1 gap-y-1">
                                                          <div>
                                                            <DeliverableChip status={order.status} />
                                                          </div>
                                                          {order.shared === true && (
                                                            <div>
                                                              <DeliverableChip status={"external"} />
                                                            </div>
                                                          )}
                                                        </div>
                                                      </td>
                                                      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                              Hantera<span className="sr-only">, {order.name}</span>
                                                          </a>
                                                      </td>
                                                  </tr>
                                              );
                                          })
                                      ) : (
                                          <tr>
                                              <td colSpan="7" className="text-center py-5">Inga beställningar hittades.</td>
                                          </tr>
                                      )}
                                  </tbody>
                                  <tfoot className="divide-y divide-gray-200 bg-white">
                                  </tfoot>
                              </table>
                          </div>
                      </div>
                  </div>
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
          </div>
      </div>
  );
}
