import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

// Initiala flikar definierade utanför komponenten
const initialTabs = [
  { name: 'Allmänt', href: 'general', current: false },
  { name: 'Integrationer', href: 'integrations', current: false },
  { name: 'Transportörer', href: 'couriers', current: false },
  { name: 'Utskick', href: '#', current: false, disabled: true },
  { name: 'Prenumeration', href: '#', current: false, disabled: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Settings() {
  // State för att spåra aktiv flik
  const [activeTab, setActiveTab] = useState('Allmänt');

  // Uppdatera tabs-arrayen baserat på aktiv flik
  const tabs = initialTabs.map(tab => ({
    ...tab,
    current: tab.name === activeTab,
  }));

  return (
    <div className="border-gray-200 pb-5 sm:pb-0">
      <h3 className="text-base font-semibold leading-6 text-gray-900">Inställningar</h3>
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            value={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name} disabled={tab.disabled}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.disabled ? '#' : tab.href}
                className={classNames(
                  tab.disabled
                    ? 'border-transparent text-gray-400 cursor-not-allowed'
                    : tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
                aria-disabled={tab.disabled ? 'true' : undefined}
                onClick={(e) => {
                  if (tab.disabled) {
                    e.preventDefault(); // Förhindra navigering om fliken är inaktiverad
                  } else {
                    setActiveTab(tab.name);
                  }
                }}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      {<Outlet />}
    </div>
  );
}
