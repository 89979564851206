import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Select, Button, useDisclosure} from "@nextui-org/react";
import { useEffect } from "react";

export default function ShareModal({openState, setOpenState}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  useEffect(() => {
    if (openState == true) {
      onOpen();
    } 
  }, [openState]);

  useEffect(() => {
    if (isOpen == false) {
        setOpenState(false);
    }
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Dela med extern miljö</ModalHeader>
              <ModalBody>
                <p> 
                  Gör den här ordern tillgänglig för ett annat konto i Flowtrade. Hantera kopplade konton i inställningar.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Stäng
                </Button>
                <Button color="primary" onPress={onClose}>
                  Dela
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}