import React, { useState } from 'react'; 
import logo from '../../assets/flowtrade-high-resolution-logo-transparent.png'
import { useAuth } from '../../contexts/AuthContext'
import ErrorMessage from './loginError';
import {CircularProgress} from "@nextui-org/react"
import { useNavigate } from "react-router-dom";


const Login = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState('');
  const auth = useAuth();
  const { loading } = useAuth();
  const navigate = useNavigate();

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    if (input.email !== "" && input.password !== "") {
      const errorMessage = await auth.loginAction(input);
      if (errorMessage) {
        setError(errorMessage);
        console.log(errorMessage);
      }
    } else {
      setError('Please provide a valid input');
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleForgotClick = () => {
    navigate('/forgot');
  }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-6 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Logga in
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmitEvent}>
            {error && <ErrorMessage message={error} />}
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                E-postadress
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="example@email.com"
                  autoComplete="email"
                  required
                  value={input.email}
                  onChange={handleInput}
                  className={`block w-full rounded-md border-0 py-1.5 ${error ? 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6' : ' text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'}`}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Lösenord
                </label>
                <div className="text-sm">
                  <button type='button' onClick={handleForgotClick} className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Glömt lösenord?
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={input.password}
                  onChange={handleInput}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Logga in
              </button>
            </div>
            <div className="flex justify-center items-center h-full">
              {loading && <CircularProgress aria-label="Loading..." size="sm" />}
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Har du inget konto?{' '}
            <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Skaffa Flowtrade
            </a>
          </p>
        </div>
      </div>
    )
}

export default Login;