import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import {Progress} from "@nextui-org/react";
import { progress } from 'framer-motion';

export default function GeneralError({message, type}) {
  const [show, setShow] = useState(true); // Kontrollerar visningen av notisen
  const [fadeOut, setFadeOut] = useState(false);
  const [progressValue, setProgressValue] = useState(0); 

  const isError = type === 'error';
  const iconClasses = "h-5 w-5 " + (isError ? "text-red-400" : "text-green-400");
  const bgColor = isError ? "bg-red-50" : "bg-green-50";
  const textColor = isError ? "text-red-800" : "text-green-800";
  const Icon = isError ? ExclamationCircleIcon : CheckCircleIcon;

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true); // Börjar fade-out-effekten
      setTimeout(() => setShow(false), 500); // Väntar tills animationen är klar innan notisen tas bort
    }, 4000); // Ange tiden efter vilken fade-out-effekten ska börja (t.ex., 5 sekunder)
  
    return () => clearTimeout(timer); // Rensar timern om komponenten avmonteras före tidens utgång
  }, []); // Tomma beroenden [] betyder att effekten körs när komponenten monteras

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressValue(prevValue => {
        if (prevValue >= 100) {
          clearInterval(interval); // Stoppa intervallet när progressValue når 100
          return prevValue; // Returnera nuvarande värde utan att ändra det
        }
        return prevValue + 1.4; // Öka värdet med 20 varje sekund, justera detta värde baserat på hur snabbt du vill att progressbaren ska fyllas
      });
    }, 50); // Uppdaterar varje 1000 millisekunder (1 sekund)
  
    return () => clearInterval(interval); // Rensar intervallet när komponenten avmonteras
  }, []);
  

  if (!show) return null; // Om show är false, rendera inte notisen

  return show ? (
    <div className="fixed top-3 right-0 m-4 z-50">
      <div className={`relative w-96 rounded-md ${bgColor} p-4 transition-opacity duration-500 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
             <Icon className={iconClasses} aria-hidden="true" />   
            <div className="ml-3">
                <p className={`text-sm font-medium ${textColor}`}>{message}</p>
            </div>
          </div>
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`inline-flex rounded-md ${bgColor} p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50`}
              onClick={() => setShow(false)}
            >
              <span className="sr-only">Stäng</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        {/* Progress bar läggs längst ner */}
        <div className="absolute bottom-0 left-0 right-0">
          <Progress size="sm" value={progressValue} />
        </div>
      </div>
    </div>
  ) : null;
  }
  