import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { PhotoIcon, UserCircleIcon, PlusIcon } from '@heroicons/react/24/solid'
import {Spinner, Switch} from "@nextui-org/react";
import { Dialog, DialogPanel, DialogTitle, DialogBackdrop } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import useUserProfileUpdate from  '../../contexts/profileHook.js'
import { useState, useEffect } from 'react'

const statuses = {
    true: 'text-green-700 bg-green-50 ring-green-600/20',
    false: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    undefined: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
  
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ExternalEnvironments({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                      Externa miljöer
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-500">
                    Hantera externa Flowtrade-miljöer för att kunna samarbeta med andra organisationer.
                    </p>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function Environments() {
    const [isAddmode, setIsAddmode] = useState(false)
    
    return ( 
        <>
        {isAddmode === false && (
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <Switch isSelected={true} >
                    Aktivera
                </Switch>
                <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                <PlusIcon className="h-5 w-5 mr-1" /> Ny paketmall
                </button>
            </div>
        )}
        </>
    )
}

export { ExternalEnvironments }