import { Fragment, useState, useContext, useEffect } from 'react'
import { useAddOrderComment } from '../../contexts/orderHook';
import useUserData from '../../contexts/dashboardHook.js';
import { useUpdate } from '../../contexts/updateContext';
import {Spinner} from "@nextui-org/react";
import { CheckCircleIcon, RocketLaunchIcon, PencilIcon } from '@heroicons/react/20/solid'
import {
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon,
    HeartIcon,
    PaperClipIcon,
    XMarkIcon,
  } from '@heroicons/react/20/solid'

  import { Listbox, Transition, Switch } from '@headlessui/react'

  const moods = [
    { name: 'Taggad', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Gillar', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
    { name: 'Glad', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Ledsen', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
    { name: 'Tummen upp', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
    { name: 'Jag har inga känslor', value: null, icon: XMarkIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function formatDateTime(dateString) {
    if (!dateString) return "Laddar..."; // Hanterar null eller undefined

    try {
        const date = new Date(dateString);
        const options = {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', hour12: false
        };
        return new Intl.DateTimeFormat('sv-SE', options).format(date).replace(/\//g, '-');
    } catch (error) {
        console.error("Error formatting date:", error);
        return "Datumfel"; // Returnerar en fallback-sträng vid fel
    }
}

function getInitials(name) {
    return name
      .split(' ') // Dela upp namnet i delar
      .slice(0, 2) // Begränsa till max två delar
      .reduce((acc, part) => acc + part[0].toUpperCase(), ''); // Hämta och lägg till de första bokstäverna
  }
  

function getUserInitials(firstName, lastName) {
    // Säkerställer att om firstName eller lastName är undefined, används ett tomt sträng som fallback.
    const initialFirst = firstName ? firstName[0].toUpperCase() : '';
    const initialLast = lastName ? lastName[0].toUpperCase() : '';
    return initialFirst + initialLast;
  }


function createSvg(initials, color = "#007bff") {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                  <rect width="100%" height="100%" fill="${color}"/>
                  <text x="50%" y="50%" dy="0.35em" text-anchor="middle" fill="#ffffff" font-size="20" font-family="Arial">${initials}</text>
                </svg>`;
  
    return `data:image/svg+xml,${encodeURIComponent(svg)}`;
  }

function getActivityDescription(type, personName) {
    const typeDescriptions = {
      created: <> <span className="font-medium text-gray-900">{personName}</span> importerade beställningen.</>,
      updated: <> <span className="font-medium text-gray-900">{personName}</span> uppdaterade beställningen.</>,
      sent: <> <span className="font-medium text-gray-900">{personName}</span> skickade beställningen.</>,
      shipped: <> <span className="font-medium text-gray-900">{personName}</span> skapade en ny fraktsedel.</>,
      // Lägg till fler typer här
    };
  
    return typeDescriptions[type] || <> <span className="font-medium text-gray-900">{personName}</span> did something with the order.</>; // Fallback-text om typen inte är känd
  }

const OrderComments = ({ orderId, orderData, setOrderData }) => {
    const { addComment, loading, error, success } = useAddOrderComment();
    const [comment, setComment] = useState('');
    const { setUpdateCount, updateCount } = useUpdate();
    const userData = useUserData();

    const [selected, setSelected] = useState(moods[5])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const result = await addComment(orderId, comment);
        if (result.success) {
            console.log('Before updating orderData', orderData.activity);
            // Skapa en ny aktivitet
            const newActivity = {
                id: orderData.activity.length + 1, //length-bugg?
                type: "commented",
                person: { name: "Du" },  // Detta borde anpassas till faktiska användardata
                comment: comment,
                date: new Date()  // Datum då kommentaren läggs till
            };

            // Uppdatera orderData med den nya aktiviteten
            const updatedOrderData = {
                ...orderData,
                activity: [...orderData.activity, newActivity]
            };

            console.log('After updating orderData', updatedOrderData.activity);
            setOrderData(updatedOrderData);

            // Rensa kommentarsfältet
            setComment('');
            setUpdateCount(prev => prev + 1);
        }
    };


    const activity = orderData?.activity;

    return (
        <>
        <div className="mt-4 flex gap-x-3">
            {userData?.imageSrc ? (
                <img
                    src={userData?.imageSrc}
                    alt={`${userData?.firstName} ${userData?.lastName}`}
                    className="h-6 w-6 flex-none rounded-full bg-gray-50"
                />
            ) : (
                <img
                src={createSvg(getUserInitials(userData?.firstName, userData?.lastName))}
                alt="Initials"
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
              />
            )}
            <form onSubmit={handleSubmit} className="relative flex-auto">
            <div className="overflow-hidden rounded-lg pb-12 shadow ring-1 ring-inset bg-white ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label htmlFor="comment" className="sr-only">
                Lägg till en anteckning...
                </label>
                <textarea
                rows={2}
                name="comment"
                id="comment"
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Lägg till en anteckning..."
                value={comment}
                onChange={e => setComment(e.target.value)}
                disabled={loading}
                />
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div className="flex items-center space-x-5">
                <div className="flex items-center">
                    <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                        <>
                        <Listbox.Label className="sr-only">Your mood</Listbox.Label>
                        <div className="relative">
                            <Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                            <span className="flex items-center justify-center">
                                {selected.value === null ? (
                                <span>
                                    <FaceSmileIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                    <span className="sr-only">Add your mood</span>
                                </span>
                                ) : (
                                <span>
                                    <span
                                    className={classNames(
                                        selected.bgColor,
                                        'flex h-8 w-8 items-center justify-center rounded-full'
                                    )}
                                    >
                                    <selected.icon className="h-5 w-5 flex-shrink-0 text-white" aria-hidden="true" />
                                    </span>
                                    <span className="sr-only">{selected.name}</span>
                                </span>
                                )}
                            </span>
                            </Listbox.Button>

                            <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            >
                            <Listbox.Options className="absolute bottom-10 z-10 -ml-6 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                                {moods.map((mood) => (
                                <Listbox.Option
                                    key={mood.value}
                                    className={({ active }) =>
                                    classNames(
                                        active ? 'bg-gray-100' : 'bg-white',
                                        'relative cursor-default select-none px-3 py-2'
                                    )
                                    }
                                    value={mood}
                                >
                                    <div className="flex items-center">
                                    <div
                                        className={classNames(
                                        mood.bgColor,
                                        'flex h-8 w-8 items-center justify-center rounded-full'
                                        )}
                                    >
                                        <mood.icon
                                        className={classNames(mood.iconColor, 'h-5 w-5 flex-shrink-0')}
                                        aria-hidden="true"
                                        />
                                    </div>
                                    <span className="ml-3 block truncate font-medium">{mood.name}</span>
                                    </div>
                                </Listbox.Option>
                                ))}
                            </Listbox.Options>
                            </Transition>
                        </div>
                        </>
                    )}
                    </Listbox>
                </div>
                    <div className="flex items-center">
                    <button
                    type="button"
                    className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    >
                    <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Attach a file</span>
                    </button>
                    {loading && <Spinner />}
                </div>
                </div>
                <button
                type="submit"
                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                Lägg till
                </button>
            </div>
            </form>
        </div>
        <div className="overflow-hidden mb-4 mt-4 sm:mb-0">
                    <ul role="list" className="space-y-6">
                        {activity?.slice().reverse().map((activityItem, activityItemIdx) => (
                        <li key={activityItem.id} className="relative flex gap-x-4">
                            <div
                            className={classNames(
                                activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6',
                                'absolute left-0 top-0 flex w-6 justify-center'
                            )}
                            >
                            <div className="w-px bg-gray-200" />
                            </div>
                            {activityItem.type === 'commented' ? (
                            <> 
                                {activityItem.person.imageUrl ? (
                                    <img
                                    src={activityItem.person.imageUrl}
                                    alt=""
                                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                                    />
                                ) : (
                                    <img
                                    src={createSvg(getInitials(activityItem.person.name))}
                                    alt="Initials"
                                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                                  />
                                )}
                                <div className="flex-auto rounded-md p-3 ring-1 ring-inset bg-white ring-gray-200">
                                <div className="flex justify-between gap-x-4">
                                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                                    <span className="font-medium text-gray-900">{activityItem.person.name}</span> kommenterade
                                    </div>
                                    <time dateTime={activityItem.dateTime} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                    {formatDateTime(activityItem.date)}
                                    </time>
                                </div>
                                <p className="text-sm leading-6 text-gray-500">{activityItem.comment}</p>
                                </div>
                            </>
                            ) : (
                            <>
                                <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                {activityItem.type === 'sent' ? (
                                    <CheckCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                                ) : (
                                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                )}
                                </div>
                                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                {getActivityDescription(activityItem.type, activityItem.person.name)}
                                </p>
                                <time dateTime={activityItem.dateTime} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                {formatDateTime(activityItem.date)}
                                <p className="flex-auto text-xs leading-5 text-gray-500"></p>
                                </time>
                                
                            </>
                            )}
                        </li>
                        ))}
                    </ul>
                </div>
        </>
    )
}

export default OrderComments;