import { useState, useEffect } from 'react';
import { useUpdate } from './updateContext';
import toast, { Toaster } from 'react-hot-toast';

function useCourierUpdate() {
    const [courierData, setCourierData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const { setUpdateCount } = useUpdate();

    const addCourier = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        const loadingToastId = toast.loading('Verifierar kopplingen till fraktbolaget🚚');

        try{
            const token = localStorage.getItem('site');

            const response = await fetch(process.env.REACT_APP_ADD_COURIER_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (!response.ok) {
                let errorMessage = "Ett fel inträffade";
                switch (response.status) {
                  case 400:
                    errorMessage = "Kopplingen kunde inte valideras. Kontrollera uppgifterna.";
                    break;
                  case 412:
                    errorMessage = "Alla fält måste fyllas i";
                    break;
                  case 403:
                    errorMessage = "Det finns redan en koppling med samma uppgifter";
                    break;
                  case 500:
                    errorMessage = "Internt serverfel";
                    break; 
                  default:
                    errorMessage = data.message || "Ett okänt fel inträffade";
                }
                throw new Error(errorMessage);
            }
            setCourierData(data);
            setSuccess(true);
            toast.success('Sådär! Fraktbolaget är kopplat ✅', { id: loadingToastId, duration: 4000 });
            setUpdateCount(prevCount => prevCount + 1);
        } catch (err) {
            setError(err.message);
            toast.error(err.message, { id: loadingToastId });
        } finally {
            setLoading(false);
        }
    };

    const deleteCourier = async (courierId) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        const loadingToastId = toast.loading('Tar bort kopplingen...');

        try {
            const token = localStorage.getItem('site');

            const response = await fetch(`${process.env.REACT_APP_DELETE_COURIER_URL}/${courierId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Ett okänt fel inträffade vid borttagning av transportören');
            }
            
            setSuccess(true)
            toast.success('Transportören har tagits bort ✅', { id: loadingToastId, duration: 4000 });
            setUpdateCount(prevCount => prevCount + 1);
        } catch (error) {
            setError(error.message);
            toast.error('Ett fel inträffade', { id: loadingToastId, duration: 4000 });
        } finally {
            setLoading(false);
        }

    }

    const updateCourier = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        const loadingToastId = toast.loading('Sparar transportör...');

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_DELETE_COURIER_URL}/${formData._id}`, {
                method: 'PUT',
                headers: {
                    'Content-type': 'Application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            })
            const data = response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Ett okänt fel inträffade');
            }
            setSuccess(true);
            setUpdateCount(prevCount => prevCount + 1);
            toast.success('Transportören uppdaterades!', { id: loadingToastId, duration: 4000 });
        } finally {
            setLoading(false);
        }
    }

    const resetError = () => setError(null);

    return { addCourier, deleteCourier, updateCourier, courierData, loading, error, success, resetError };

}

const useCourierData = () => {
    const [courierData, setCourierData] = useState(null);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const { updateCount } = useUpdate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('site');
                const response = await fetch(process.env.REACT_APP_GET_COURIERS_URL, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'Application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setCourierData(data);
            } catch (error) {
                console.error('Failed to fetch courier data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [updateCount]);

    const getServices = async (courierId) => {
        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_GET_SERVICES_URL}/${courierId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setServices(data);
        } catch (error) {
            console.error('Failed to fetch services:', error);
            setServices([]);
        }
    }

    return { courierData, loading, getServices, services };
}

export { useCourierUpdate, useCourierData }