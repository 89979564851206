import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const AddIntegrationMessage = ({ message }) => {
    const messageMap = {
        "Authentication Failed": "Uppgifterna stämmer inte.",
        "Failed to fetch": "Ett internt serverfel uppstod.",
        "Validation failed": "Uppgifterna stämmer inte.",
        "Too many attempts. Please try again in 15 minutes.": "För många försök. Prova igen om 15 minuter."
        // Lägg till fler felkoder och meddelanden här
      };
    
      const displayMessage = messageMap[message] || message;
    
    return (
        <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Det gick inte att lägga till integrationen. <Link to="/dash/faq" className="text-blue-500 hover:text-blue-700">Varför?</Link></h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                {message}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default AddIntegrationMessage;