import React, { useState, useEffect } from 'react'; 
import { useNavigate, useLocation } from "react-router-dom";
import useUserProfileUpdate from '../../contexts/profileHook';
import logo from '../../assets/flowtrade-high-resolution-logo-transparent.png';
import ErrorMessage from './loginError';
import GeneralError from './generalError';
import { CircularProgress } from "@nextui-org/react";

const NewPassword = () => {
  const [input, setInput] = useState({
    password: "",
    password2: ""
  });
  const [error, setError] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { setPassword, loading, error: apiError, success } = useUserProfileUpdate();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get('resetToken');

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [success, navigate]);

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    if (input.password !== input.password2) {
      setError('Lösenorden matchar inte.');
      return;
    }

    if (input.password !== "") {
      const formData = {
        resetToken: resetToken,
        password: input.password
      };

      try {
        setSubmitDisabled(true)
        await setPassword(formData);
      } catch (errorMessage) {
        setError(errorMessage);
      } finally {
        setSubmitDisabled(false)
      }
    } else {
      setError('Ange ett giltigt lösenord.');
    } 
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-6 w-auto"
          src={logo}
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Välj ett nytt lösenord.
        </h2>
        <p className="mt-2 text-center text-sm text-gray-500">
          Ange ett nytt lösenord som ska användas för åtkomst till kontot. Lösenordet måste innehålla minst 6 tecken.
        </p>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-4" onSubmit={handleSubmitEvent}>
          {error||apiError && <ErrorMessage message={error||apiError} />}
          {success && success === true && <GeneralError message='Återställningen lyckades! Du omdirigeras nu till inloggningssidan.' />}
          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Lösenord
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                value={input.password}
                onChange={handleInput}
                className={`block w-full rounded-md border-0 py-1.5 ${error ? 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6' : ' text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'}`}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password2" className="block text-sm font-medium leading-6 text-gray-900">
                Upprepa lösenord
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password2"
                name="password2"
                type="password"
                required
                value={input.password2}
                onChange={handleInput}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={submitDisabled}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Godkänn
            </button>
          </div>
          <div className="flex justify-center items-center h-full">
            {loading && <CircularProgress aria-label="Loading..." size="sm" />}
          </div>
        </form>

        <p className="mt-5 text-center text-sm text-gray-500">
          <a href="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Tillbaka till inloggning
          </a>
        </p>
      </div>
    </div>
  );
};

export default NewPassword;
