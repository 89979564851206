import React, { useEffect, useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Switch, useDisclosure, Select, SelectItem, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell} from "@nextui-org/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useCourierUpdate, useCourierData } from "../../contexts/courierHook";
import toast from 'react-hot-toast'

const services = [
    {serviceName: "DHL Paket", productCode: "102"},
    {serviceName: "DHL Parcel Connect Plus", productCode: "112"},
    {serviceName: "DHL Service Point (utlämningsställe)", productCode: "103"},
    {serviceName: "DHL Service Point Retur", productCode: "104"},
    {serviceName: "DHL Parcel Connect", productCode: "109"},
    {serviceName: "DHL Parcel Return Connect", productCode: "107"}
  ];


function DoubleCheck({courier}) {
  const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();
  const { deleteCourier, loading, error, success } = useCourierUpdate();

  const handleSubmit = async (event) => {
    const courierId = courier._id;

    try{
      onClose();
      await deleteCourier(courierId);
    } catch (error) {
      toast.error(error.message || 'Okänt fel');
    }
  };

  return (
    <>
      <Button color="danger" variant="ghost" onPress={onOpen}>
          <TrashIcon
              className="h-5 w-5 shrink-0 text-red-500 group-hover:text-white"
              aria-hidden="true"
          />
            Ta bort transportör
      </Button> 
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Är du säker?</ModalHeader>
              <ModalBody>
                <p> 
                  Vill du verkligen ta bort transportören? Detta kan inte ångras.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Nej, avbryt
                </Button>
                <Button color="danger" type="ghost" onPress={handleSubmit}>
                  Ja, ta bort
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );

}

export default function CourierSettings({ courier }) {
  const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();

  const { deleteCourier, updateCourier, loading, error, success } = useCourierUpdate();
  const { getServices, services } = useCourierData();

  const [selectedServices, setSelectedServices] = useState([]);
  const [isActive, setIsActive] = useState(courier.active);
  const [isAuto, setIsAuto] = useState(courier.autoServicePoint);
  const [isTest, setIsTest] = useState(courier.testMode || false);
  const [isPackageTypes, setIsPackageTypes] = useState(courier.packageTypes);

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);

  useEffect(() => {
    if (courier.activeServices) {
      const fullServiceDetails = courier.activeServices.map(service => ({
        productCode: service.productCode,
        serviceName: service.serviceName // Antag att det här värdet finns tillgängligt.
      }));
      setSelectedServices(fullServiceDetails);
    }
  }, [courier.activeServices]);

  useEffect(() => {
    getServices(courier.courierId);
  }, [courier.courierId]);

  const handleSelectChange = (value) => {
    // Antag att 'value' är en array av 'productCode' från de valda alternativen.
    setSelectedServices(services.filter(service => value.includes(service.productCode)));
  };

  const handleIsActiveChange = (event) => {
    const newIsActiveValue = event.target.checked;  // Hämtar 'checked'-värdet från switchen
    console.log("Nytt värde för isActive:", newIsActiveValue);  // Loggar det korrekta värdet
    setIsActive(newIsActiveValue);  // Uppdaterar tillståndet med det korrekta värdet
  };

  const handleIsAutoChange = (event) => {
    const newIsAutoValue = event.target.checked;
    setIsAuto(newIsAutoValue);
  };

  const handleIsTestChange = (event) => {
    const newIsTestValue = event.target.checked;
    setIsTest(newIsTestValue);
  };

  const handlePackageTypesChange = (event) => {
    const newIsPackageTypesValue = event.target.checked;
    setIsPackageTypes(newIsPackageTypesValue);
  };



  console.log('Selectedservices:', selectedServices);

  const handleSubmitUpdate = async () => {
    try {
      const formData = {
        _id: courier._id,
        activeServices: selectedServices,
        active: isActive,
        testMode: isTest,
        autoServicePoint: isAuto,
        packageTypes: isPackageTypes
      };
      console.log(formData)
      await updateCourier(formData);
      onClose();
    } catch (error) {
      console.log(error.message)
      toast.error(error.message || 'Okänt fel vid uppdatering');
    }
  };


  return (
    <>
      <button onClick={onOpen} className="text-indigo-600 hover:text-indigo-900">Hantera</button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior="inside">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Hantera {courier.courierName}</ModalHeader>
              <ModalBody>
                <Switch defaultSelected={courier.active} onChange={handleIsActiveChange}>
                    Aktiv
                </Switch>
                <p className="text-small text-default-500">När kopplingen är inaktiv går det inte att boka frakter med transportören.</p>
                <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                        <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900">Frakttjänster</span>
                    </div>
                </div>
                <Select 
                    label="Välj frakttjänster" 
                    selectionMode="multiple"
                    className="w-full"
                    defaultSelectedKeys={selectedServices.map(service => service.productCode)}
                    onChange={(e) => handleSelectChange(e.target.value)}
                >
                    {services.map((service) => (
                    <SelectItem key={service.productCode} value={service.productCode}>
                        {service.serviceName}
                    </SelectItem>
                    ))}
                </Select>
                <p className="text-small text-default-500">Välj vilka frakttjänster som ska användas med den här transportören.</p>
                <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                        <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900">Preferenser</span>
                    </div>
                </div>
                <Switch defaultSelected={courier.autoServicePoint} onChange={handleIsAutoChange}>
                    Välj ombud automatiskt
                </Switch>
                <p className="text-small text-default-500">{isAuto ? 'Systemet väljer automatiskt det närmaste ombudet.' : 'Ombud väljs manuellt vid fraktbokning.'}</p>
                <Switch defaultSelected={courier.packageTypes} onChange={handlePackageTypesChange}>
                    Använd paketmallar (om aktivt)
                </Switch>
                <p className="text-small text-default-500">{isPackageTypes ? 'Paketmallar kan väljas vid fraktbokning.' : 'Paketmallar är inaktiverat för det här fraktsättet.'}</p>
                <Switch defaultSelected={courier.testMode || false} onChange={handleIsTestChange} color="warning">
                    Testläge
                </Switch>
                <p className="text-small text-default-500">{isTest ? 'Skapa fraktsedlar utan att skicka bokningar till transportören.' : 'Fraktbokningar skapas för produktion.'}</p>
                <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                        <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900"></span>
                    </div>
                </div>
                <div className="flex justify-left text-gray-900">
                      <DoubleCheck courier={courier}/>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Avbryt
                </Button>
                <Button color="primary" onPress={handleSubmitUpdate}>
                  Spara
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
