import { React, useState } from 'react';
import useUserData from '../../contexts/dashboardHook.js';
import useUserProfileUpdate from  '../../contexts/profileHook.js'
import {Spinner} from "@nextui-org/react";
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import toast from 'react-hot-toast';

const isNotEmpty = (value) => value.trim() !== '';
const isValidZip = (zip) => /^[0-9]{5}$/.test(zip);


export default function Profile() {
    const userData = useUserData();
    const { updateProfile, loading, error, success } = useUserProfileUpdate();
    const [validationError, setValidationError] = useState(null);
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setValidationError(null); // Rensa tidigare fel
    
        const formData = {
            companyName: event.target.company.value,
            regNumber: event.target.registration.value,
            companyCity: event.target.city.value,
            companyZip: event.target.elements['postal-code'].value,
            companyAddress: event.target.elements['street-address'].value
        };
    
        try {
            if (!Object.values(formData).every(value => value.trim() !== '')) {
                throw new Error("Alla fält måste vara ifyllda.");
            }
    
            if (!/^[0-9]{5}$/.test(formData.companyZip)) {
                throw new Error("Postnumret är ogiltigt.");
            }
    
            await updateProfile(formData);
        } catch (error) {
            setValidationError(error.message);
            toast.error(error.message);
        }
    };
    

    


    return (
      <div className="border-b border-gray-200 pb-5">
        <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Konto</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">Redigera organisationens kontouppgifter</p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                Företagsnamn
              </label>
              <div className="mt-2">
              <input
                  type="text"
                  name="company"
                  id="company"
                  defaultValue={userData?.companyName}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="registration" className="block text-sm font-medium leading-6 text-gray-900">
                Organisationsnummer
              </label>
              <div className="mt-2 relative">
              <input
                  type="text"
                  name="registration"
                  id="registration"
                  defaultValue={userData?.regNumber}
                  aria-invalid="true"
                  aria-describedby="email-error"
                  className={`block w-full rounded-md border-0 py-1.5 ${error ? 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6' : ' text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'}`}
                  />
              </div>
            </div>


            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                E-postadress
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  defaultValue={userData?.email}
                  disabled
                  type="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
                <p className="mt-2 text-xs text-gray-500" id="email-description">
                Det går inte att ändra e-postadressen.
                </p>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                Land
              </label>
              <div className="mt-2">
                <select
                  id="country"
                  name="country"
                  autoComplete="country-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option>Sverige</option>
                </select>
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                Adress
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  defaultValue={userData?.companyAddress}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2 sm:col-start-1">
              <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                Postnummer
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  defaultValue={userData?.companyZip}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                Ort
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  defaultValue={userData?.companyCity}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        {loading && <Spinner />}
        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
          Avbryt
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Spara
        </button>
      </div>
    </form>
      </div>
      
      
      
    )
  }

//   <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
//   <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
// </div>