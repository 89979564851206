import { useState, useEffect } from 'react';

const useUserData = () => {
    const [userData, setUserData] = useState(null);
    
    useEffect(() => {
      const fetchData = async () => {
        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_PROFILE_URL, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`
                }
              });
          const data = await response.json();
          setUserData(data);
          console.log(data);
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      };
      
      fetchData();
    }, []);
  
    return userData;
  };
  
export default useUserData;