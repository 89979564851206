import React from 'react'; 
import {Skeleton} from '@nextui-org/react'

export default function TableSkeleton() {
    return (
        <div className="max-w-[300px] w-full flex items-center gap-3">
        <div>
          <Skeleton className="flex rounded-full w-12 h-12"/>
        </div>  
        <div className="w-full flex flex-col gap-2">
          <Skeleton className="h-3 w-3/5 rounded-lg"/>
          <Skeleton className="h-3 w-4/5 rounded-lg"/>
        </div>
      </div>
    )
}

export function SkeletonRow() {
    return (
        <tr className="animate-pulse">
            {/* Checkbox column */}
            <td className="relative px-7 sm:w-12 sm:px-6">
                <Skeleton className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded-full"/>
            </td>
            {/* PlatformOrderId column */}
            <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
                <Skeleton className="h-4 w-32 rounded-md"/>
            </td>
            {/* Customer Name column */}
            <td className="whitespace-nowrap px-3 py-4">
                <Skeleton className="h-4 w-48 rounded-md mb-2"/>
                <Skeleton className="h-4 w-36 rounded-md"/>
            </td>
            {/* Customer Email column */}
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Skeleton className="h-4 w-48 rounded-md"/>
            </td>
            {/* Total Value column */}
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Skeleton className="h-4 w-24 rounded-md"/>
            </td>
            {/* Status column */}
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Skeleton className="h-4 w-20 rounded-md"/>
            </td>
            {/* Edit Button column */}
        </tr>
    );
}