import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import { useState } from "react";

export default function NewInvoice() {
  const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();
  const [orderId, setOrderId] = useState(null);

  const handleSubmit = async () => {
    window.open(`/customs/invoice/${orderId}`, '_blank');
    onClose()
  }

  const handleInputChange = (event)=> {
    const { value } = event.target;
    setOrderId(value);
  }


  return (
    <>
    <button
        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={onOpen}
    >
        Skapa ny
    </button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Ny tullfaktura</ModalHeader>
              <ModalBody>
                <p> 
                  Ange ordernumret till ordern som faktura ska skapas för.
                </p>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Ordernummer
                    </label>
                    <div className="mt-2">
                        <input
                        id="orderId"
                        name="orderId"
                        type="text"
                        placeholder="050966"
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Stäng
                </Button>
                <Button color="primary" onPress={handleSubmit}>
                  Skapa faktura
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}