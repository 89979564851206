import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Example from './stats'


  const stats = [
    { name: 'Omsättning idag', stat: '71,897 kr', previousStat: '', prefix: '', change: '12%', changeType: 'increase' },
    { name: 'Andel levererat inom 24h', stat: '58%', previousStat: '56%', prefix: 'igår ', change: '2.02%', changeType: 'increase' },
    { name: 'Väntande upphämtningar', stat: '1 st', previousStat: '', prefix: '', change: '', changeType: 'increase' },
    { name: 'Skapade frakter', stat: '98 st', previousStat: '87 st', prefix: 'igår ', change: '4.05%', changeType: 'decrease' },
  ];

  const data = [
    { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Jul', uv: 7892, pv: 4300, amt: 2100 },
  ];
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  

export default function Dashboard() {
  

  return (
    <>
    <div className="flex flex-col sm:flex-row mt-4 mx-auto max-w-7xl sm:space-x-4 items-stretch">
      <div className="w-full sm:basis-3/5 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          {/* Left column content goes here */}
          <div className="bg-white pb-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Översikt</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Omsättning under de senaste 7 dagarna
                </p>
              </div>
              <div className="ml-4 mt-4 flex-shrink-0">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Hantera
                </button>
              </div>
            </div>
          </div>
          <LineChart width={600} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pv" stroke="#8884d8" />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </div>
      </div>
      <div className="w-full sm:basis-2/5 overflow-hidden rounded-lg bg-white shadow mt-4 sm:mt-0">
        <div className="px-4 py-5 sm:p-6">
          {/* Right column content goes here */}
          <div className="bg-white sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Mest använda fraktmetoder</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Dina vanligaste fraktmetoder de senaste 7 dagarna 
                </p>
              </div>
            </div>
          </div>
          <Example/>
        </div>
      </div>
    </div>
    <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-4 mx-auto max-w-7xl">
      {stats.map((item) => (
        <div key={item.name} className="overflow-hidden rounded-xl bg-white shadow px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">{item.name}</dt>
          <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
              {item.stat}
              <span className="ml-2 text-sm font-medium text-gray-500"> {item.prefix} {item.previousStat}</span>
            </div>
            <div
              className={classNames(
                item.changeType === 'increase' ? 'text-green-800' : 'text-red-800',
                'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
              )}
            >
              {item.changeType === 'increase' ? (
                <ArrowUpIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <ArrowDownIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                  aria-hidden="true"
                />
              )}
              <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
              {item.change}
            </div>
          </dd>
        </div>
      ))}
    </div>
    <Example/>
    </>
  )
};