import React, { useState, useEffect } from 'react'; 
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import useUserProfileUpdate from '../../contexts/profileHook';
import GeneralError from './generalError';
import logo from '../../assets/flowtrade-high-resolution-logo-transparent.png';
import ErrorMessage from './loginError';
import { CircularProgress } from "@nextui-org/react";

const Forgot = () => {
  const [input, setInput] = useState({ email: "" });
  const [error, setError] = useState('');
  const { resetPassword, loading, success } = useUserProfileUpdate();
  const navigate = useNavigate();

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    if (input.email !== "") {
      try {
        await resetPassword({ email: input.email, userAgent: navigator.userAgent });
      } catch (errorMessage) {
        setError(errorMessage);
      }
    } else {
      setError('Please provide a valid email address.');
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [success, navigate]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-6 w-auto"
          src={logo}
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Glömt ditt lösenord?
        </h2>
        <p className="mt-2 text-center text-sm text-gray-500">
          Ange kontots e-postadress för att skicka ett återställningsmejl.
        </p>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmitEvent}>
          {error && <ErrorMessage message={error} />}
          {success && success === true && <GeneralError message='Återställningsmejlet har skickats! Du omdirigeras till inloggningssidan.' />}
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              E-postadress
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                placeholder="example@email.com"
                autoComplete="email"
                required
                value={input.email}
                onChange={handleInput}
                className={`block w-full rounded-md border-0 py-1.5 ${error ? 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6' : ' text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'}`}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Skicka
            </button>
          </div>
          {success && <p className="text-green-600 text-center text-sm">Återställningsmejlet har skickats! Du omdirigeras till inloggningssidan.</p>}
          <div className="flex justify-center items-center h-full">
            {loading && <CircularProgress aria-label="Loading..." size="sm" />}
          </div>
        </form>

        <p className="mt-5 text-center text-sm text-gray-500">
          <a href="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Tillbaka till inloggning
          </a>
        </p>
      </div>
    </div>
  );
};

export default Forgot;
