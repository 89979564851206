import { useState, useEffect } from 'react';
import { useUpdate } from './updateContext';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';



function useIntegrationUpdate() {
    const [integrationData, setIntegrationData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const location = useLocation();
    const { setUpdateCount } = useUpdate();

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');
      const shop = queryParams.get('shop');

      const integrationName = shop ? shop.split(".")[0] : 'Min Shopify-integration'
      console.log(shop);

      const input = {
        ...(code && { code }),
        ...(shop && { shop }),
        platformId: '3',
        apiKey: 'placeholder',
        integrationName: integrationName,
        domain: integrationName,
      }

      if (code && code.length >= 10) {
        updateIntegration(input)
      }
    }, [location]);

    const updateIntegration = async (formData) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        const loadingToastId = toast.loading('Håll i hatten, nu kör vi! 🎩✨');

        try {
            const token = localStorage.getItem('site');

            const response = await fetch(process.env.REACT_APP_INTEGRATION_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (!response.ok) {
                let errorMessage = "Ett fel inträffade";
                switch (response.status) {
                  case 400:
                    errorMessage = "Integrationen kunde inte valideras.";
                    break;
                  case 412:
                    errorMessage = "Alla fält måste fyllas i";
                    break;
                  case 403:
                    errorMessage = "Det finns redan en integration till det här systemet.";
                    break;
                  case 500:
                    errorMessage = "Internt serverfel";
                    break; 
                  default:
                    errorMessage = data.message || "Ett okänt fel inträffade";
                }
                throw new Error(errorMessage);
            }
            setIntegrationData(data);
            setSuccess(true);
            toast.success('Klart! Integrationen är nu skapad och redo att ta över världen! 🌍🚀', { id: loadingToastId, duration: 4000 });
            setUpdateCount(prevCount => prevCount + 1);
        } catch (err) {
            setError(err.message);
            toast.error(err.message, { id: loadingToastId });
        } finally {
            setLoading(false);
        } 
    };

    const editIntegration = async (formData) => {
      setLoading(true);
      setError(null);
      setSuccess(null);

      const loadingToastId = toast.loading('Sparar...');

      try {
          const token = localStorage.getItem('site');

          const response = await fetch(`${process.env.REACT_APP_INTEGRATION_URL}/${formData._id}`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, 
              },
              body: JSON.stringify(formData),
          });
          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.message || 'Ett okänt fel inträffade');
        }
          setIntegrationData(data);
          setSuccess(true);
          toast.success('Integrationen uppdaterades! 🚀', { id: loadingToastId, duration: 4000 });
          setUpdateCount(prevCount => prevCount + 1);
      } catch (err) {
          setError(err.message);
          toast.error(err.message, { id: loadingToastId });
      } finally {
          setLoading(false);
      }
  };

    const resetError = () => setError(null);

    return { updateIntegration, editIntegration, integrationData, loading, error, success, resetError};
}

const useIntegrationData = () => {
    const [integrationData, setIntegrationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { updateCount } = useUpdate();

    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_GET_INTEGRATION_URL, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              }
            });
            const data = await response.json();
            console.log("APi-response:", data);
            setIntegrationData(data);
          } catch (error) {
            console.error('Failed to fetch user data:', error);
          } finally {
            setLoading(false);
          }
        };
      
        fetchData();
      }, [updateCount]); // Lägg till updateCount här
  
    return { integrationData, loading };
  };

const useLocationData = (_id) => {
  const [locationData, setLocationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { updateCount } = useUpdate();

  useEffect(() => {
    const fetchData = async () => {
      if (!_id) return;
      try {
        const token = localStorage.getItem('site');
        const response = await fetch(`${process.env.REACT_APP_GET_SHOPIFY_LOCATIONS_URL}/${_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        });
        const data = await response.json();
        console.log("Data:", data.locations);
        setLocationData(data.locations || []);
      } catch (error) {
        console.error('Failed to fetch location data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [updateCount]); // Lägg till updateCount här

return { locationData, loading };

}
export default useIntegrationUpdate;
export { useIntegrationData, useLocationData };